import storage from "../provider/Storage";

const phoneFormat = (val) => {
    let ret;
    if (val && val.length === 11) {
        ret =
            val.substring(0, 3) + "-" + val.substring(3, 7) + "-" + val.substring(7);
    } else if (val && val.startsWith("02") && val.length === 10) {
        ret =
            val.substring(0, 2) + "-" + val.substring(2, 6) + "-" + val.substring(6);
    } else if (val && val.length === 10) {
        ret =
            val.substring(0, 3) + "-" + val.substring(3, 6) + "-" + val.substring(6);
    } else {
        ret = val;
    }
    return ret;
};

const dateFormat = (val) => {
    let ret = "";
    if (val && val.length === 8) {
        ret = val.substring(0, 4) + "-" + val.substring(4, 6) + "-" + val.substring(6);
    }else if (val && val.length === 12) {
        ret = val.substring(0, 4) + "-" + val.substring(4, 6) + "-" + val.substring(6,8) + " " + val.substring(8, 10) + ":" + val.substring(10);
    } else {
        ret = val;
    }
    return ret;
};

const numberFormat = (num) => {
    return String(num).toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
}


const isEmpty = (param) => {
    return Object.keys(param).length === 0;
}


const getToken = () => {
    const tokenData = storage.getToken();
    if (tokenData) {
        return tokenData;
    } else {
        return {};
    }
}

const getTokenWithType = () => {
    let tokenObject = getToken();
    if (isEmpty(tokenObject)) {
        return "";
    } else {
        return `${tokenObject.type} ${tokenObject.token}`;
    }
}

const removeToken = () => {
    storage.removeToken();
}

/*
[{key:'', name:'', format:()=>{} }]
*/

const petSex = (code) => {
    return "M,N".indexOf(code) >= 0 ? "수컷" : "암컷";
}
const petNeutralYn = (code) => {
    return "N,G".indexOf(code) >= 0 ? "O" : "X";
}
const petAge = (monthCnt) => {
    if(monthCnt > 0){
        return monthCnt < 12 ? monthCnt + ' 개월' : Math.floor(monthCnt / 12) + ' 세 ' + (monthCnt % 12) + ' 개월';
    }else {
        return monthCnt;
    }
}
const petSize = (sizeType) => {
    if(sizeType === "S") {
        return "소형";
    }else if(sizeType === "M") {
        return "중형";
    }else if(sizeType === "B") {
        return "대형";
    }else {
        return "코드없음";
    }
}

const parseJwt = (token) => {
    let base64Url = token.split('.')[1];
    let base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    let jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
};

const utils = {
    phoneFormat,
    numberFormat,
    dateFormat,
    isEmpty,
    getToken,
    getTokenWithType,
    removeToken,
    petSex,
    petNeutralYn,
    petAge,
    petSize,
    parseJwt
};

export default utils;