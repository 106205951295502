import {useEffect, useRef, useState} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import REST from "../lib/api";
import storage from "../lib/provider/Storage";
const NaverCallback= ()=>{

    const location = useLocation();
    const navigate = useNavigate();
    const [modalState, setModalState] = useState(false);
    const param = useRef(null);

    useEffect(() => {
        naverLoad().then(r => {});
    }, [modalState]);
    const naverLoad = async () => {
        const hashStr = location.hash.slice(1).split(/&/);
        const toJson = {};
        hashStr.forEach((item=>{toJson[item.split("=")[0]] = item.split("=")[1]}));

        // const hashStr = location.hash.slice(1).split(/&|=/); https://test.peterslab.net/web_login/
        if(toJson.length < 4){alert("잘못된 URL 입니다."); navigate(-1);}

        //
        const paramTemp = {
            snsToken: toJson["access_token"],
            snsId: toJson["state"],
            snsName: "",
            tokenType: toJson["token_type"],
            snsType: "NAV"
        };
        param.current = paramTemp;
        const snsId = await REST.getNaverUser(paramTemp);
        if(snsId["code"] === 200) paramTemp["snsId"] = snsId["data"]["result"];
        else return alert(`서비스 중 오류가 났습니다.\n 오류코드 : ${snsId["code"]}`);

        let result = await REST.login(paramTemp);
        if (result["code"] === 407) {
            showModal();
            // navigate("/agree", {state: param});
        } else if (result["code"] === 200) {
            storage.saveToken(result["data"]);
            navigate("/config?type=네이버", {state: paramTemp});
        } else {
            alert(`서비스 중 오류가 났습니다.\n 오류코드 : ${result["code"]}`);
            // navigate(-1);
        }
    }

    const showModal = ()=> {
        setModalState(true);
    };

    const resisterOk = ()=>{
        setModalState(false);
        if(param.current !== null ){navigate("/agree", {state: param.current});}
    };

    return(
        <>
            <div>
                <div
                    className={`absolute bg-black opacity-50 top-0 left-0 w-full h-full flex flex-col justify-center content-center ${modalState ? "" : "hidden"}`}></div>
                <div
                    className={`absolute top-0 left-0 w-full h-full flex flex-col justify-center p-6 items-center ${modalState ? "" : "hidden"}`}>
                    <div
                        className="max-w-[540px] w-full py-[28px] mx-[10px] bg-white rounded-[3px] flex flex-col justify-center items-center space-y-4 ">
                        <div className="lg:text-[30px] text-[20px] font-bold">회원가입</div>
                        <div className="lg:text-[25px] text-[17px] text-[#8E919A]">본인인증이 필요합니다.</div>
                        <div className="lg:text-[25px] text-[17px] text-white bg-[#15BDE8] rounded-full lg:px-[80px] px-[70px] py-[12px]" onClick={resisterOk}>확인</div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default NaverCallback;

