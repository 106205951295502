import axios from "axios";
import utils from "../utils/Util";
import {SERVER_URL} from "../../config/config";

let BASE_URL = SERVER_URL;


const axiosClient = axios.create({
    baseURL: BASE_URL,
    headers: {
        //"Content-Type": "application/json",
        "X-Naver-Client-Id":"cbMfMXvaHt56qf3SCX3E",
        "X-Naver-Client-Secret":"GT8uyJNsI8",
    },
});

axiosClient.interceptors.request.use(function (request) {
    console.log("REQUEST[INTERCEPTORS]", request.params);
    console.log("REQUEST[INTERCEPTORS]", request.data);

    const _author = utils.getTokenWithType();
    if (_author) {
        request.headers = {
            Authorization: _author,
            //Accept: "application/json",
        };
    }
    return request;
});
axiosClient.interceptors.response.use(
    function (response) {
        console.log("RESPONSE[INTERCEPTORS]", response);
        return response.data;
    },
    function (error) {
        console.log("ERROR => ", error);
        if (error.response) {
            let res = error.response;
            console.log("오류: Status Code: ==>" + res.status);
            if (res.status === 401) {
                // utils.removeToken();
                window.location.href = `#/login`;
            }
        } else {
            console.log("Other ERROR : =>", error);
        }
        return Promise.reject(error);
    }
);

export default axiosClient;
