// export const SERVER_URL = "http://localhost:8080";
export const SERVER_URL = "https://test.peterslab.net";
// export const SERVER_URL = "https://rest2.peterslab.net";

export const STORAGE_NAME = "PLJP_TOKEN";
export const REDIRECT_URL = "https://shop.peterslab.net/auth";

export const CLIENT_ID = "CfSIfSCfxJ1vEyXS4HvUvP";
export const CLIENT_ID_T = "QVNJbIqWMpblkshoaxvuZC";
export const CLIENT_STATE = "jennerBioLink";


export const CLIENT_SECRET = "lfOYHlV0Ca7bhdUWRLmtrB";
export const CLIENT_SCOPE = "mall.read_customer,mall.write_customer,mall.read_privacy,mall.write_privacy,mall.read_personal,mall.read_product,mall.read_order,mall.read_promotion,mall.write_promotion,mall.read_community,mall.write_community";

export const KAKAO_TOKEN = "240e614bd129520993422d3fdbb522e0";
export const NAVER_CLIENT_ID = "cbMfMXvaHt56qf3SCX3E";
export const NAVER_CALLBACK_URL = "https://peterslab.net/web_login/#/naver_callback";
export const CAFE24_CODE = "peterslab_cafe24_code";
