import KakaoLogin from "react-kakao-login";
import NaverLogin from 'react-naver-login';
import {useLocation, useNavigate} from "react-router-dom";
import REST from "../lib/api";
import {useEffect, useRef, useState} from "react";

import login_main from "../img/login_main.png";
import ic_kakao from "../img/ic_kakao.png";
import ic_naver from "../img/ic_naver.png";
import {CAFE24_CODE, KAKAO_TOKEN, NAVER_CALLBACK_URL, NAVER_CLIENT_ID} from "../config/config";


const LoginView = () => {

    const [modalState, setModalState] = useState(false);
    const param = useRef(null);
    // let param = null;
    // const naverCallbackUrl = "http://localhost:3000/web_login/#/naver_callback";
    // const appleClientId = "com.jennerbio.peterslab03";

    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
    }, [modalState, param]);

    const onKSuccess = async (data) => {
        // 카카오 성공 시
        const accessToken = data.response["access_token"];
        const userId = String(data.profile.id);
        const userNickname = String(data.profile.name);
        const tokenType = data.response["token_type"];
        // eslint-disable-next-line react-hooks/rules-of-hooks
        const params = new URLSearchParams(location.search);

        const paramTemp = {
            snsToken: accessToken,
            snsId: userId,
            snsName: userNickname,
            tokenType: tokenType,
            snsType: "KKO"
        };
        param.current = paramTemp; // 송신데이터 상태관리 저장
        let result = await REST.login(paramTemp); // 회원인지 확인
        if (result["code"] === 407) { // 회원가입이 안되어 있을 때
            showModal(); // 모달창을 띄워준다.
            // navigate("/agree", {state: param});
        } else if (result["code"] === 200) { // 회원가입되어있는 사람이면
            alert("테스트서버 인증했다.");
            window.location.href = `${params.get("redirect_uri")}?code=${CAFE24_CODE}&state=${params.get("state")}`;
            // storage.saveToken(result["data"]); // 토큰을 웹케시에 저장한다
            // navigate("/config?type=카카오", {state: paramTemp}); // 화면 이동
        } else {
            alert(`서비스 중 오류가 났습니다.\n 오류코드 : ${result["code"]}`);
        }
    }


    const onKFail = async (e) => {
        // let result = await REST.login({snsType: "KKO"});
        // console.log(result);
    }

    const showModal = () => {
        setModalState(true);
    };

    const resisterOk = () => {
        // 확인 버튼
        setModalState(false); // 모달창 닫기
        if (param.current !== null) { // 로그인 정보가 케시데이터에 있는지 확인
            navigate("/agree", {state: param.current}); // 화면 이동
        }
    };

    return (
        <>
            <div className="h-screen flex flex-col flex-wrap justify-center content-center">
                <div className="max-w-[640px] w-full space-y-4 px-[30px]">
                    <div className="flex justify-center">
                        <img className="lg:w-[324px] w-[230px]" src={login_main} alt={"login_main"}/>
                    </div>
                    <div className="text-center py-[40px] text-[#8E919A] lg:text-[25px] text-[17px]">카카오 | 네이버로<br/>쉽고 빠르게 회원가입하세요.
                    </div>
                    <KakaoLogin
                        className=""
                        token={KAKAO_TOKEN}
                        onSuccess={onKSuccess}
                        onFail={onKFail}
                        render={KakaoButton}
                    />
                    <NaverLogin
                        clientId={NAVER_CLIENT_ID}
                        callbackUrl={NAVER_CALLBACK_URL}
                        render={(props) => <NaverButton props={props}/>}/>
                </div>
                <div className={`absolute bg-black opacity-50 top-0 left-0 w-full h-full flex flex-col justify-center content-center ${modalState ? "" : "hidden"}`}></div>
                <div className={`absolute top-0 left-0 w-full h-full flex flex-col justify-center p-6 items-center ${modalState ? "" : "hidden"}`}>
                    <div className="max-w-[540px] w-full py-[28px] mx-[10px] bg-white rounded-[3px] flex flex-col justify-center items-center space-y-4">
                        <div className="lg:text-[30px] text-[20px] font-bold">회원가입</div>
                        <div className="lg:text-[25px] text-[17px] text-[#8E919A]">본인인증이 필요합니다.</div>
                        <div className="lg:text-[25px] text-[17px] text-white bg-[#15BDE8] rounded-full lg:px-[80px] px-[70px] py-[12px]" onClick={resisterOk}>확인</div>
                    </div>
                </div>
            </div>
        </>
    );
}
const NaverButton = ({props}) => {
    return (
        <>
            <div className="bg-[#00C73C] w-full rounded-[3px] flex flex-row justify-center items-center py-3 space-x-[10px] hover:cursor-pointer"
                 onClick={(e) => {
                     e.preventDefault();
                     props.onClick();
                 }}>
                <img className="w-[25px]" src={ic_naver} alt={"ic_naver"}/>
                <div className="text-[20px] text-white">네이버 아이디</div>
            </div>
        </>
    );
}

const KakaoButton = ({onClick}) => {
    return (
        <>
            <div
                className="bg-[#ffeb00] w-full rounded-[3px] flex flex-row justify-center items-center py-3 space-x-[10px] hover:cursor-pointer"
                onClick={(e) => {
                    e.preventDefault();
                    onClick();
                }}>
                <img className="w-[25px]" src={ic_kakao} alt={"ic_kakao"}/>
                <div className="text-[20px]">카카오 아이디</div>
            </div>
        </>
    )
};
export default LoginView;

