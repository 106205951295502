import {createBrowserRouter} from "react-router-dom";
import AppIntro from "./views/AppIntro";
import AuthCode from "./views/AuthCode";
import ChildCode from "./views/ChildCode";
import LoginView from "./views/LoginView";
import NaverCallback from "./views/NaverCallback";


// const routes = createHashRouter([
const routes = createBrowserRouter([
    {
        path: "/", element: <AppIntro/>,
        children: [
            {path: "/child", element: <ChildCode/>},
        ],
    },
    {path: "/auth", element: <AuthCode/>},
    {path: "/login", element: <LoginView/>},
    { path: "/naver_callback", element:<NaverCallback />}

], );


export default routes;