import * as config from "../../config/config";
import {STORAGE_NAME} from "../../config/config";

const storageName = STORAGE_NAME;


const saveToken = (data) => {
    window.localStorage.setItem(storageName, JSON.stringify(data));
    console.log("set token", data);
}

const getToken = () => {
    //console.log("get token");
    const tokenData = window.localStorage.getItem(storageName);
    if (tokenData) {
        return JSON.parse(tokenData);
    } else {
        return {};
    }
}
const removeToken = () => {
    //console.log("remove token");
    window.localStorage.removeItem(storageName);
}

const Storage = {saveToken, getToken, removeToken};

export default Storage;
