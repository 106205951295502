import {useEffect} from "react";
import {useLocation} from "react-router-dom";
import {CLIENT_ID, CLIENT_ID_T, CLIENT_SCOPE, CLIENT_STATE, REDIRECT_URL} from "../config/config";

const AppIntro = ()=>{

    const location = useLocation();
    const params = new URLSearchParams(location.search);

    useEffect(() => {
        loadData();
    }, []);

    const loadData = ()=>{
        console.log("Hi Intro Page");
        if(params.get("mall_id") !== null){
            // window.location.href = `https://${params.get("mall_id")}.cafe24api.com/api/v2/oauth/authorize?response_type=code&client_id=${CLIENT_ID}&state=${CLIENT_STATE}&redirect_uri=${REDIRECT_URL}&scope=${CLIENT_SCOPE}`;
        }
    };

    const sendOauth = () => {
        console.log("Bye Intro Page");
        window.location.href = `https://${params.get("mall_id")}.cafe24api.com/api/v2/oauth/authorize?response_type=code&client_id=${CLIENT_ID}&state=${CLIENT_STATE}&redirect_uri=${REDIRECT_URL}&scope=${CLIENT_SCOPE}`;
        // console.log(`https://${params.get("mall_id")}.cafe24api.com/api/v2/oauth/authorize?response_type=code&client_id=${CLIENT_ID}&state=${CLIENT_STATE}&redirect_uri=${REDIRECT_URL}&scope=${CLIENT_SCOPE}`);
    }

    const sendOauthTest = () => {
        console.log("Bye Intro Page");
        window.location.href = `https://${params.get("mall_id")}.cafe24api.com/api/v2/oauth/authorize?response_type=code&client_id=${CLIENT_ID_T}&state=${CLIENT_STATE}&redirect_uri=${REDIRECT_URL}&scope=${CLIENT_SCOPE}`;
        // console.log(`https://${params.get("mall_id")}.cafe24api.com/api/v2/oauth/authorize?response_type=code&client_id=${CLIENT_ID}&state=${CLIENT_STATE}&redirect_uri=${REDIRECT_URL}&scope=${CLIENT_SCOPE}`);
    }

    return (<>
        <div className="flex w-full h-dvh justify-center items-center bg-amber-400">
            <div>
                <div>AppIntro params : {params.get("mall_id")}</div>
                <div><a onClick={sendOauth}>인트로 보내기</a></div>
                <div><a onClick={sendOauthTest}>테스트 인트로 보내기</a></div>
            </div>
        </div>
    </>);
}

export default AppIntro;