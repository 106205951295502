import {useEffect, useState} from "react";
import {useLocation} from "react-router-dom";
import {CLIENT_ID, CLIENT_SECRET} from "../config/config";

const AuthCode = () => {
    const [authCode, setAuthCode] = useState("");
    const [base64Code, setBase64Code] = useState("")
    const location = useLocation();
    const params = new URLSearchParams(location.search);

    useEffect( () => {
        loadData().then(r => console.log(""));
    }, []);

    const loadData = async () => {
        // setBase64Code("Basic " + btoa(`${CLIENT_ID}:${CLIENT_SECRET}`));
        if (params.get("code") !== null) {
            const formData = new FormData();
            formData.append("grant_type","authorization_code");
            formData.append("code",params.get("code"));
            formData.append("redirect_uri","https://shop.peterslab.net/rest/auth/cafe24_token");
            await fetch("https://peterslab.cafe24api.com/api/v2/oauth/token",{
                method : "post",
                headers : {
                    "Authorization" : "Basic " + btoa(`${CLIENT_ID}:${CLIENT_SECRET}`),
                    "Content-Type" : "application/x-www-form-urlencoded",
                    "Access-Control-Allow-Origin": "*",
                },
                body : formData,
            }).then((res)=>{console.log(res);console.log("포스트 이벤트 종료")});
            setAuthCode(params.get("code"));
        } else {
            setAuthCode("No Data");
        }
    }

    return (<>
        <div className="flex flex-col w-full h-dvh justify-center items-center bg-amber-400">
            <div>AuthCode : {authCode}</div>
            <div>base64 encode : 완료되었습니다 엑세스 토큰을 이용하세요</div>
        </div>
    </>);
}

export default AuthCode;