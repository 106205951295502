import {useEffect, useState} from "react";
import {useLocation} from "react-router-dom";

const ChildCode = ()=>{
    const [authCode, setAuthCode] = useState("");
    const location = useLocation();
    useEffect(() => {
        setAuthCode(location.state);
    }, []);

    return (<>
        <div>ChildCode : {authCode}</div>
    </>);
}

export default ChildCode;