import client from "./client";

const login = (param) => {
    return client.post(`/v2/auth/login`, param);
};

const certificationSignUp = (param) => {
    return client.post(`/v2/auth/certifications`, param);
};
const loadMemberInfo = (param) => {
    return client.post(`/v3/mobile/member/loading`, param);
};
const memberOut = () => {
    return client.post(`/v2/mobile/member/out`);
};

const getNaverUser = (param) => {
    return client.post(`/v3/mobile/get_naver_check`, param);
};

const api = {
    login,
    certificationSignUp,
    loadMemberInfo,
    memberOut,
    getNaverUser
};

export default api;